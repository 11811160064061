import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ResultService } from 'src/app/services/result.service';

interface IOpciones {
  id: number;
  titulo: string;
  valor: number;
  paginaAc: number;
  estado: boolean;
  productos: Array<any>;
  problema: string;
}

interface IEncuesta {
  id: number;
  pregunta: string;
  problema: string;
  respuestas: IOpciones[]
}

@Component({
  selector: 'app-question-one',
  templateUrl: './question-one.component.html',
  styleUrls: ['./question-one.component.scss']
})
export class QuestionOneComponent implements OnInit {

  textoBotonNext: string = 'SIGUIENTE';

  paginaActual = 1;

  pagina: string = 'pagina';

  optionsSelected: IOpciones[] = [];

  testActive = true;

  encuestafinal = [];

  button_questions = [
    {
      id: 1,
      pregunta: 'Tus líneas de expresión y arrugas se perciben como:',
      problema: 'Líneas de expresión y arrugas',
      respuestas: [
        {
          id: 101,
          titulo: "Muy pocas / Poco profundas",
          valor: 1,
          paginaAc: 1,
          estado: false,
          productos: ['33000']
        },
        {
          id: 102,
          titulo: "Pocas / Poco profundas",
          valor: 2,
          paginaAc: 1,
          estado: false,
          productos: ['100720', '100885']
        },
        {
          id: 103,
          titulo: "Algunas / Profundas",
          valor: 3,
          paginaAc: 1,
          estado: false,
          productos: ['100720', '100721', '100884', '100885', '101131', '101129', '101130']
        },
        {
          id: 104,
          titulo: "Muchas / Profundas",
          valor: 4,
          paginaAc: 1,
          estado: false,
          productos: ['100720', '100721', '100884', '100885', '101131', '101129', '101130']
        }
      ]
    },
    {
      id: 2,
      pregunta: '¿Dónde te gustaría ver tu piel más firme?',
      problema: 'Firmeza',
      respuestas: [
        {
          id: 105,
          titulo: "Más firme en todo el rostro",
          valor: 1,
          paginaAc: 2,
          estado: false,
          productos: ['100721', '100884', '100885']
        },
        {
          id: 106,
          titulo: "Más firme en la zona de los ojos y peri bucal",
          valor: 2,
          paginaAc: 2,
          estado: false,
          productos: ['101302']
        },
        {
          id: 107,
          titulo: "Más firme en todo el rostro y ojos",
          valor: 3,
          paginaAc: 2,
          estado: false,
          productos: ['100721',	'100884',	'100885',	'101302']
        }
      ]
    },
    {
      id: 3,
      pregunta: 'Tu piel presenta manchas que...',
      problema: 'Manchas, tono irregular',
      respuestas: [
        {
          id: 108,
          titulo: "Han aparecido con el paso del tiempo",
          valor: 1,
          paginaAc: 3,
          estado: false,
          productos: ['100720', '101113', '101115',	'101116']
        },
        {
          id: 109,
          titulo: "Han aparecido por brotes de acné",
          valor: 2,
          paginaAc: 3,
          estado: false,
          productos: [, '101113',	'101115',	'101116', '101533']
        }
      ]
    },
    {
      id: 4,
      pregunta: '¿Cada brote de acné es diferente, como describirías el tuyo?',
      problema: 'Acné',
      respuestas: [
        {
          id: 110,
          titulo: "Puntos negros y espinillas",
          valor: 1,
          paginaAc: 4,
          estado: false,
          productos: ['101512', '101533']
        },
        {
          id: 111,
          titulo: "Brotes de acné",
          valor: 2,
          paginaAc: 4,
          estado: false,
          productos: ['101536', '101118']
        },
        {
          id: 112,
          titulo: "Puntos negros y brotes de acné",
          valor: 3,
          paginaAc: 4,
          estado: false,
          productos: ['101512',	'101536']
        }
      ]
    },
    {
      id: 5,
      pregunta: 'En qué área del rostro tu piel se siente reseca? ',
      problema: 'Resequedad, escamacion',
      respuestas: [
        {
          id: 113,
          titulo: "Frente",
          valor: 1,
          paginaAc: 5,
          estado: false,
          productos: ['100721',	'100050',	'100560',	'100673']
        },
        {
          id: 114,
          titulo: "Mejillas",
          valor: 2,
          paginaAc: 5,
          estado: false,
          productos: ['100721',	'100050',	'100560',	'100673']
        },
        {
          id: 115,
          titulo: "Todo el rostro",
          valor: 3,
          paginaAc: 5,
          estado: false,
          productos: ['100721',	'100050',	'100560',	'100673']
        }
      ]
    }

  ];

  encuesta = [

    {
      id: 2,
      pregunta: 'Sin usar hidratante, dirías que sentís tu piel',
      respuestas: [
        {
          id: 1,
          titulo: "Incómoda, tirante",
          valor: 1,
          paginaAc: 1,
          estado: false
        },
        {
          id: 2,
          titulo: "Cómoda, brillo en nariz y frente",
          valor: 2,
          paginaAc: 1,
          estado: false
        },
        {
          id: 3,
          titulo: "Con brillo indeseado",
          valor: 3,
          paginaAc: 1,
          estado: false
        }
      ]
    },
    {
      id: 3,
      pregunta: '¿Cuántos años tenés?',
      respuestas: [
        {
          id: 4,
          titulo: "Menos de 20",
          valor: 1,
          paginaAc: 2,
          estado: false
        },
        {
          id: 5,
          titulo: "Entre 20 y 35",
          valor: 2,
          paginaAc: 2,
          estado: false
        },
        {
          id: 6,
          titulo: "Entre 36 y 50",
          valor: 3,
          paginaAc: 2,
          estado: false
        },
        {
          id: 7,
          titulo: "Entre 51 y 65",
          valor: 4,
          paginaAc: 2,
          estado: false
        },
        {
          id: 8,
          titulo: "Más de 65",
          valor: 5,
          paginaAc: 2,
          estado: false
        }
      ]
    },
    {
      id: 4,
      pregunta: '¿Fumás?',
      respuestas: [
        {
          id: 9,
          titulo: "Si",
          valor: 1,
          paginaAc: 3,
          estado: false
        },
        {
          id: 10,
          titulo: "No",
          valor: 2,
          paginaAc: 3,
          estado: false
        }
      ]
    },
    {
      id: 5,
      pregunta: '¿Hacés actividad física?',
      respuestas: [
        {
          id: 11,
          titulo: "Si",
          valor: 1,
          paginaAc: 4,
          estado: false
        },
        {
          id: 12,
          titulo: "No",
          valor: 2,
          paginaAc: 4,
          estado: false
        }
      ]
    },
    {
      id: 6,
      pregunta: '¿Cuántas horas descansas por la noche?',
      respuestas: [
        {
          id: 13,
          titulo: "8 horas o más",
          valor: 1,
          paginaAc: 5,
          estado: false
        },
        {
          id: 14,
          titulo: "Entre 6 y 8 horas",
          valor: 2,
          paginaAc: 5,
          estado: false
        },
        {
          id: 15,
          titulo: "Menos de 6 horas",
          valor: 3,
          paginaAc: 5,
          estado: false
        }
      ]
    }
  ]

  tipoPiel;

  seleccionPaginaActual: any[] = [];

  totalPreguntas = this.encuesta.length;

  idGlobal = 0;

  constructor(private route: Router, private activatedRoute: ActivatedRoute, private resultadoService: ResultService) { }

  ngOnInit() {
    this.encuestafinal = [];
    this.activatedRoute.paramMap.subscribe(
      params => {
        // (+) before `params.get()` turns the string into a number
        //console.log(params.get('idButton'))
        //console.log("IDBUTTON",Number(params.get('idButton')))
        this.resultadoService.setResultProblema(Number(params.get('idButton')));
        this.unionEncuesta(params.get('idButton'));

      })
  }



  unionEncuesta(id) {
    //console.log("id de seleccion",id)
    this.encuestafinal.push(this.button_questions[id - 1]);
    this.encuestafinal.forEach(elem => {
      elem.id = 1;
    })
    this.encuesta.forEach(elemnts => {
      this.encuestafinal.push(elemnts);
    })
    this.encuesta = [];
    this.encuesta = this.encuestafinal;
    this.totalPreguntas = this.encuesta.length;
    //console.log("encuesta final",this.encuestafinal);

  }

  recibirClic(e) {
  }

  irSiguientePregunta() {
    if (this.paginaActual === this.totalPreguntas - 1) {
      this.textoBotonNext = 'FINALIZAR TEST';
    }
    //console.log(this.seleccionPaginaActual,this.paginaActual);
    if (this.seleccionPaginaActual.length < this.totalPreguntas) {
      //console.log(this.paginaActual)
      if (this.seleccionPaginaActual.length > this.paginaActual - 1) {
        this.paginaActual++;
      }
    } else {
      this.testActive = false;
      this.resultadoService.setResult(JSON.parse(JSON.stringify(this.seleccionPaginaActual)));
      this.route.navigate(['/resultado-test'])
    }
  }

  clickValor(e, valor, id, pregunta, productos) {


    this.optionsSelected.push({ id: id, titulo: pregunta, valor: valor, paginaAc: this.paginaActual, estado: true, productos: productos, problema: this.encuestafinal[this.paginaActual - 1].pregunta })

    this.revisarValores(this.optionsSelected);
  }

  actualizarSeleccion(obj: IOpciones[]) {
    const busquedaac = obj.find(element => element.paginaAc === this.paginaActual);
    if (busquedaac) {
      this.optionsSelected = obj;
    }
  }

  irTest() {
    this.testActive = true;
    this.paginaActual = 1;
    this.seleccionPaginaActual = [];
    //
  }

  irAnteriorPregunta() {

    this.textoBotonNext = 'SIGUIENTE';

    if (this.paginaActual > 1) {
      this.paginaActual--;
    } else {
      this.route.navigate(['/comencemos']);
      this.testActive = true;
    }


  }

  revisarValores(e) {
    if (this.seleccionPaginaActual.length) {
      const busqueda = this.seleccionPaginaActual.findIndex(element => element.paginaAc === this.paginaActual)
      if (busqueda != -1) {
        this.seleccionPaginaActual.splice(busqueda, 1);
      }
    }
    this.seleccionPaginaActual.push(e[e.length - 1]);


    this.tipoPiel = this.seleccionPaginaActual.find(element => element.paginaAc === 2)
    if (this.tipoPiel) {
      localStorage.setItem('tipopiel', JSON.stringify(this.tipoPiel.id));
    }


  }



}
