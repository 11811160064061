import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-steps-progress',
  templateUrl: './steps-progress.component.html',
  styleUrls: ['./steps-progress.component.scss']
})
export class StepsProgressComponent implements OnInit {

  public objetos_:any[];
  @Input() posicionActual:number;
  @Input() set paginaActual(value: number){
    this.posicionActual = value;
  }
  get paginaActual() {return this.posicionActual;}
  //@Input() objetos:any[] = [];
  @Input() set objetos(value:any[]){
      this.objetos_ = value;
  }
  get objetos() {return this.objetos_}
  @Output() enviarClic = new EventEmitter<any>()

  constructor() { }

  ngOnInit() {
    //console.log("THIS>OBJETOS", this.objetos_)
  }

  public enviarPosicion(){
    this.enviarClic.emit(this.paginaActual);
  }

}
