import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { urlEncuesta, httpOptions, urlEncuestaupdates, urlFraganciasupdatesSendEmail } from './constantes';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResultService {

  public resultado = new BehaviorSubject<any>(null);
  public idProblema = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }

  public setResult(obj: any): void {
    this.resultado.next(obj);
  }

  public getResult(): Observable<any>{
    return this.resultado.asObservable();
  }

  public setResultProblema(obj: number): void {
    this.idProblema.next(obj);
  }

  public getResultProblema(): Observable<any>{
    return this.idProblema.asObservable();
  }

  public destroyResult(): void {
    this.resultado.unsubscribe();
    this.resultado.unsubscribe();
  }

  public addEncuesta (encuesta): Observable<any> {
    //console.log(encuesta);
    return this.http.post<any>(environment.URL + urlEncuestaupdates, JSON.stringify(encuesta), httpOptions);
  }

  public addEncuestaEmail (email,id): Observable<any> {
   // console.log(JSON.stringify(email), id);
    return this.http.put<any>(environment.URL + urlEncuestaupdates+`/${id}`, {email:email}, httpOptions);
  }

  public sendEncuestaMail (email,contenido): Observable<any> {
   // console.log(contenido);
    return this.http.post<any>(environment.URL + urlFraganciasupdatesSendEmail, {email:email,template:contenido}, httpOptions);
  }
}
