import { Component, OnInit, ViewChild, ElementRef, AfterContentInit, AfterViewInit, HostListener, Renderer2, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comencemos',
  templateUrl: './comencemos.component.html',
  styleUrls: ['./comencemos.component.scss']
})
export class ComencemosComponent implements OnInit, AfterContentInit, AfterViewInit {

  @ViewChild('nombre', null) nombre: ElementRef; 
  public isDisabled: boolean = true;
  public classActive: boolean = false;
  constructor(private router: Router) { }
  
  ngOnInit() {
  }

  ngAfterContentInit(){
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.nombre.nativeElement.focus();
      this.isDisabled = false;
      //this.classActive = true;
    }, 100);
  }

  nextPage(nombre){
   
  }

 
  irTestPre(id: number, nombre:string){
    if(id===4){
      this.classActive = true;
    }else {
      this.classActive = false;
    }
      localStorage.setItem('nombre',nombre);
    setTimeout(() => {
      this.router.navigate(['/question-one',{idButton : id}]);   
    }, 500);
   
      }

}
