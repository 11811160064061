import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuardGuard implements CanActivate {

  constructor(private router: Router){}

  canActivate(){
    if (!localStorage.getItem('session')) {
      
      
      return false;
  }

  return true;

  }
  
}
