import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material';
import {MatRadioModule} from '@angular/material/radio';
import { StepsProgressComponent } from './components/steps-progress/steps-progress.component';
import { ResultadoTestComponent } from './components/resultado-test/resultado-test.component';
import { HeaderComponent } from './components/header/header.component';
import { IntroComponent } from './components/intro/intro.component';
import { HistoryComponent } from './components/history/history.component';
import { ComencemosComponent } from './components/comencemos/comencemos.component';
import { QuestionOneComponent } from './components/question-one/question-one.component';
import { ResultService } from './services/result.service';
import { ModalComponent } from './components/modal/modal.component';
import { HttpClientModule } from '@angular/common/http';
import { MailComponent } from './components/mail/mail.component';


@NgModule({
  declarations: [
    StepsProgressComponent,
    ResultadoTestComponent,
    HeaderComponent,
    IntroComponent,
    HistoryComponent,
    ComencemosComponent,
    QuestionOneComponent,
    ModalComponent,
    MailComponent,


    
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule

    


  ],
  exports: [],
  providers: [ResultService],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
