import { HttpHeaders } from '@angular/common/http';

export const urlEncuesta = "/encuestasupdates";
export const urlEncuestaupdates = "/encuestasupdates/updates";
export const urlFraganciasupdates = "/test_fragancias";
export const urlFraganciasupdatesSendEmail = "/encuestasupdates/sendmail/1";
export const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};