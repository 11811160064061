import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { StepsProgressComponent } from './components/steps-progress/steps-progress.component';
import { GuardGuard } from './services/guard.guard';
import { ResultadoTestComponent } from './components/resultado-test/resultado-test.component';
import { IntroComponent } from './components/intro/intro.component';
import { HistoryComponent } from './components/history/history.component';
import { ComencemosComponent } from './components/comencemos/comencemos.component';
import { QuestionOneComponent } from './components/question-one/question-one.component';
import { MailComponent } from './components/mail/mail.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'intro', pathMatch: 'full'
  },
    {
      path:'intro',
      component: IntroComponent 
    },
    {
      path:'maissl',
      component: MailComponent 
    },
    {
      path:'question-one',
      component: QuestionOneComponent,
      canActivate:[GuardGuard] 
    },
    {
      path:'comencemos',
      component: ComencemosComponent,
      canActivate:[GuardGuard] 
    },
    {
      path:'history',
      component: HistoryComponent,
      canActivate:[GuardGuard] 
    },
    {
      path:'steps-progress',
      component: StepsProgressComponent,
      canActivate:[GuardGuard]
    },
    {
      path:'resultado-test',
      component: ResultadoTestComponent,
      canActivate:[GuardGuard]
    }
]

@NgModule({
  declarations: [AppComponent],
  imports: [RouterModule.forRoot(routes, { useHash : true })],
  exports: [RouterModule]})
export class AppRoutingModule { }
